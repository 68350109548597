<template>
  <div>
    <el-dialog
      :title="type ? '查看广告' : '添加广告'"
      :close-on-click-modal="false"
      top="5%"
      :visible.sync="showDialog"
      width="40%"
      label-width="80px"
    >
      <el-form
        :model="addform"
        ref="addform"
        :rules="myrules"
        label-width="100px"
      >
        <el-form-item label="标题" prop="title">
          <el-input
            v-model.trim="addform.title"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="brandId">
          <el-select
            v-model="addform.brandId"
            @change="selectOne"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in brandSelectData"
              :key="index"
              :label="item.brandName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店" prop="hotelId">
          <el-select
            v-model="addform.hotelId"
            @change="changeHotel"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in hotelSelectData"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传类型" prop="advType">
          <el-select
            v-model="addform.advType"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in upLoadType"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传" prop="pathList">
          <el-upload
            class="ads-uploader"
            :action="`${imgUploadUrl}${addform.advType}`"
            :headers="tokenHeaders"
            :on-success="handleAvatarSuccess"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :accept="addform.advType == 0 ? 'image/*' : 'video/*'"
            multiple
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="imgUploadBar">
            <div
              class="avatar el-card is-always-shadow"
              v-for="(item, i) in addform.pathList"
              :key="i"
            >
              <el-image
                class="avatarImg"
                :src="item.path"
                :preview-src-list="imgList"
              >
              </el-image>
              <div class="del">
                <span class="delBtn" @click="handleRemove(i)">
                  <i class="el-icon-close"></i
                ></span>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmAdd('addform')"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  advertiseAdd,
  panelEdit,
  getHotelSelect,
  imgUploadReq,
  imgUploadUrl,
} from "@/api";
import store from '../../../../vuex/index'
export default {
  props: ["upLoadType", "brandSelectData"],
  data() {
    return {
      imgUploadUrl: imgUploadUrl,
      tokenHeaders: {
        Authentication: store.state.token,
      },
      loading: false,
      showDialog: false,
      hotelSelectData: null,
      addform: {
        brandId: null,
        brandName: null,
        hotelId: null,
        pmsHotelId: null,
        hotelName: null,

        // thumbPath: [], //缩略图图片地址
        pathList: [],
        title: null, //标题
        advType: 0, //0:图片，1:视频
        //   displayDuration: null, //显示时长（没开发此功能，暂时没用）
        //   startTime: null, //有效开始时间（没开发此功能，暂时没用）
        //   endTime: null, //有效结束时间（没开发此功能，暂时没用）
      },
      type: 0,
      myrules: {
        // uuid: [{ required: true, message: "请输入", trigger: ["blur"] }],
        brandId: [
          {
            required: true,
            message: "请选择品牌",
            trigger: ["blur", "changge"],
          },
        ],
        hotelId: [
          {
            required: true,
            message: "请选择门店",
            trigger: ["blur", "changge"],
          },
        ],
        advType: [
          {
            required: true,
            message: "请选择上传类型",
            trigger: ["blur", "changge"],
          },
        ],
        //  ip: [{ required: true, message: "请输入", trigger: ["blur",'changge'] }],
        // mainboardId: [{ required: true, message: "请输入", trigger: ["blur",'changge'] }],
        panelType: [
          { required: true, message: "请输入", trigger: ["blur", "changge"] },
        ],
        pathList: [
          {
            required: true,
            message: "请上传广告图片/视频",
            trigger: ["blur", "changge"],
          },
        ],
      },
    };
  },
  computed: {
    imgList() {
      let arr = [];
      this.addform.pathList.forEach((item) => {
        if (item.path) {
          arr.push(item.path);
        }
      });
      return arr;
    },
  },

  methods: {
    handleAvatarSuccess(res, file) {
      if (res.code == "000000" && res.data) {
        this.addform.pathList.push(res.data);
      }
    },
    changeHotel(id) {
      this.addform.pmsHotelId = null;
      if (id) {
        let index = this.hotelSelectData.findIndex((item) => item.id == id);
        if (index != -1) {
          this.addform.pmsHotelId = this.hotelSelectData[index].pmsHotelId;
          this.addform.hotelName = this.hotelSelectData[index].name;
        }
        if (!this.addform.pmsHotelId) {
          this.$message.error("未对接西软hotelid，不能使用此功能");
        }
      }
    },
    handleRemove(i) {
      this.$confirm("是否将删除当前图片", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.addform.pathList.splice(i, 1);
        })
        .catch(() => {});
    },
    beforeAvatarUpload(file) {
      const isJPG =
        this.addform.advType == 0
          ? file.type.indexOf("image/") !== -1
          : file.type.indexOf("video/") !== -1;
      const isLt2M = file.size / 1024 / 400;
      if (!isJPG) {
        this.$message.error(
          `只能上传${this.addform.advType == 0 ? "图片" : "视频"}`
        );
      } else if (this.addform.advType == 1) {
        this.$message.error(`视频还未开放`);
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 400kb!");
        return false;
      } else {
        return isJPG;
      }
      //  return true

      // const _this = this
      //   let imgWidth = ''
      //   let imgHight = ''
      //   const isSize = new Promise(function(resolve, reject) {
      //     const _URL = window.URL || window.webkitURL
      //     const img = new Image()
      //     img.onload = function() {
      //       imgWidth = img.width
      //       imgHight = img.height
      //       const valid = img.width === parseInt(_this.picWidth) && img.height === parseInt(_this.picHeight)
      //       valid ? resolve() : reject()
      //     }
      //     img.src = _URL.createObjectURL(file)
      //   }).then(() => {
      //     return file
      //   }, () => {
      //     _this.$message.warning({ message: `上传文件的图片大小不合符标准,宽需要为${_this.picWidth}px，高需要为${_this.picHeight}px。当前上传图片的宽高分别为：${imgWidth}px和${imgHight}px` })
      //     return Promise.reject()
      //   })
      //   return isSize

      //  && isLt2M;
    },
    // 获取门店下拉框
    selectOne(id) {
      this.addform.hotelId = null;
      getHotelSelect(id).then((res) => {
        if (res.data.code == "000000") {
          this.hotelSelectData = res.data.data;
        }
      });
    },
    confirmAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //  console.log(this.addform);
          let data = this.getData();
          console.log(data);
          if (!data.pmsHotelId) {
            this.$message.error("该酒店未对接西软hotelid，不能使用此功能");
            return false;
          }
          if (this.type == 0) {
            this.adsPutReq(data);
          } else {
            // data.doorPanelId = this.addform.doorPanelId;
            // this.editPanelReq(data);
          }
        } else {
          return false;
        }
      });
    },
    getData() {
      let data = new Object();
      [
        "brandId",
        "brandName",
        "pmsHotelId",
        "hotelId",
        "hotelName",
        "pathList",
        "title",
        "advType",
      ].forEach((key) => {
        data[key] = this.addform[key];
      });
      let index = this.brandSelectData.findIndex(
        (item) => item.id == data.brandId
      );
      if (index != -1) {
        data.brandName = this.brandSelectData[index].brandName;
      }
      return data;
    },
    adsPutReq(data) {
      this.loading = true;
      advertiseAdd(data)
        .then((res) => {
          this.loading = false;
          if (res.data.code == "000000") {
            this.$message.success("添加成功");
            this.$refs.addform.resetFields();
            this.showDialog = false;
            this.$parent.getPage();

            //   this.$parent.getList();
          } else {
            this.$message.error(res.data.message);
          }
          console.log(res);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    editPanelReq(data) {
      this.loading = true;
      panelEdit(data)
        .then((res) => {
          this.loading = false;
          if (res.data.code == "000000") {
            this.$message.success("添加成功");
            this.$refs.addform.resetFields();
            this.showDialog = false;
            this.$parent.getList();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    openSet(data) {
      this.showDialog = true;
      //  if (data) {
      //   this.type = 1;
      //   this.addform = Object.assign({}, data);
      //   console.log(this.addform, "this.addform");
      //  } else {
      //   if (this.type == 1) {
      //    setTimeout(() => {
      //     this.$refs.addform.resetFields();
      //    }, 300);
      //   }
      //   this.type = 0;
      //  }
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader {
  width: 50px !important;
  height: 50px !important;
}
.avatar-uploader-icon {
  font-size: 22px;
  color: #8c939d;
  height: 50px !important;
  line-height: 50px !important;
  text-align: center;
}
.avatar {
  height: 100px;
  padding: 5px;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  overflow: hidden;
  .avatarImg {
    height: 100px;
  }
  .del {
    right: -100%;
    transition: all 0.5s;
    overflow: hidden;
  }
  &:hover {
    .del {
      transition: all 0.5s;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      position: absolute;
      z-index: 5;
      right: 0;
      top: 0;
      text-align: center;
      line-height: 25px;
      background-color: rgba(0, 0, 0, 0.5);
      .delBtn {
        color: #fff;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>


