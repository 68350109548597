var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "广告详情",
            "close-on-click-modal": false,
            top: "3%",
            visible: _vm.showDialog,
            width: "60%",
            "label-width": "80px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _vm.showSync
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "success", size: "mini" },
                              on: { click: _vm.handleSync },
                            },
                            [_vm._v(" 同步")]
                          )
                        : _vm._e(),
                      _vm.showClean
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "warning", size: "mini" },
                              on: { click: _vm.cleanAds },
                            },
                            [_vm._v(" 清除")]
                          )
                        : _vm._e(),
                      _c("span", { staticClass: "beforeDiv" }),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v("\n      广告详情\n     "),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "\n     " +
                        _vm._s(_vm.detailData.brandName) +
                        " - " +
                        _vm._s(_vm.detailData.hotelName) +
                        " "
                    ),
                    _c("span", [
                      _vm._v("pmsHotelId:" + _vm._s(_vm.detailData.pmsHotelId)),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-card", { attrs: { shadow: "always" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "infinite-scroll",
                    rawName: "v-infinite-scroll",
                    value: _vm.load,
                    expression: "load",
                  },
                ],
                staticClass: "detailTab",
                style: "height:" + _vm.height + "px",
                attrs: { "infinite-scroll-disabled": "disabled" },
              },
              [
                _c(
                  "el-table",
                  {
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tablist, "tooltip-effect": "dark" },
                    on: { "selection-change": _vm.tab },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", width: "55" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "title", label: "标题" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "advType", label: "广告类型" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n        " +
                                    _vm._s(_vm.showLabel(scope.row.advType)) +
                                    "\n       "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "图片/视频" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                [
                                  _c("el-image", {
                                    staticClass: "avatarImg",
                                    attrs: {
                                      src: scope.row.path,
                                      "preview-src-list": _vm.imgList,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "状态" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n        " +
                                    _vm._s(
                                      scope.row.isShowing == 1 ? "正在展示" : ""
                                    ) +
                                    "\n\n       "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "80" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.showDelete
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDelete(
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }