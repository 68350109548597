var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.type ? "查看广告" : "添加广告",
            "close-on-click-modal": false,
            top: "5%",
            visible: _vm.showDialog,
            width: "40%",
            "label-width": "80px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: {
                model: _vm.addform,
                rules: _vm.myrules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addform.title,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "title",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "品牌", prop: "brandId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.selectOne },
                      model: {
                        value: _vm.addform.brandId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "brandId", $$v)
                        },
                        expression: "addform.brandId",
                      },
                    },
                    _vm._l(_vm.brandSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.brandName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门店", prop: "hotelId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.changeHotel },
                      model: {
                        value: _vm.addform.hotelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "hotelId", $$v)
                        },
                        expression: "addform.hotelId",
                      },
                    },
                    _vm._l(_vm.hotelSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上传类型", prop: "advType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.addform.advType,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "advType", $$v)
                        },
                        expression: "addform.advType",
                      },
                    },
                    _vm._l(_vm.upLoadType, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上传", prop: "pathList" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "ads-uploader",
                      attrs: {
                        action: "" + _vm.imgUploadUrl + _vm.addform.advType,
                        headers: _vm.tokenHeaders,
                        "on-success": _vm.handleAvatarSuccess,
                        "show-file-list": false,
                        "before-upload": _vm.beforeAvatarUpload,
                        accept:
                          _vm.addform.advType == 0 ? "image/*" : "video/*",
                        multiple: "",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "imgUploadBar" },
                    _vm._l(_vm.addform.pathList, function (item, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "avatar el-card is-always-shadow",
                        },
                        [
                          _c("el-image", {
                            staticClass: "avatarImg",
                            attrs: {
                              src: item.path,
                              "preview-src-list": _vm.imgList,
                            },
                          }),
                          _c("div", { staticClass: "del" }, [
                            _c(
                              "span",
                              {
                                staticClass: "delBtn",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRemove(i)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmAdd("addform")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }