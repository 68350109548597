<template>
 <div class="my-box" v-loading="loading">
  <!-- 功能区域 -->
  <el-row>
   <el-card shadow="always">
    <el-form label-width="80px" class="topS" ref="seekData" :model="seekData">
     <el-row :gutter="10">
      <el-col :span="4">
       <el-button @click="openpopup" v-if="showAdd">新增</el-button>
      <span class="beforeDiv"></span>
      </el-col>
      <el-col :span="4">
       <el-form-item label="品牌" prop="brandId">
        <el-select v-model="seekData.brandId" @change="selectOne" clearable filterable placeholder="请选择">
         <el-option v-for="(item,index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id"></el-option>
        </el-select>
       </el-form-item>
      </el-col>
      <el-col :span="4">
       <el-form-item label="门店" prop="brandId">
        <el-select v-model="seekData.hotelId" clearable filterable placeholder="请选择">
         <el-option v-for="(item,index) in hotelSelectData" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
       </el-form-item>
      </el-col>
      <!-- <el-col :span="4">
       <el-form-item label="标题" prop="title">
        <el-input v-model.trim="seekData.title" placeholder="请输入" maxlength="15"></el-input>
       </el-form-item>
      </el-col> -->
      <!-- <el-col :span="4">
       <el-form-item label="RoomId" prop="pmsRoomId">
        <el-input v-model.trim="seekData.pmsRoomId" placeholder="请输入" maxlength="15"></el-input>
       </el-form-item>
      </el-col> -->
      <el-col :span="12" class="reset-button">
       <el-button type="primary" @click="handleSearch">查询</el-button>
       <el-button @click="reset">重置</el-button>
      </el-col>
     </el-row>
    </el-form>
   </el-card>
  </el-row>
  <!-- 内容区域 -->
  <el-row>
   <el-card shadow="always">
    <div class="my-header-table">
     <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" :height="height" style="width: 100%" @selection-change="tab">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="brandName" label="品牌"></el-table-column>
      <el-table-column prop="hotelName" label="门店"></el-table-column>
      <el-table-column prop="pmsHotelId" label="PMS HotelId"></el-table-column>
      <el-table-column prop="titleCount" label="图片/视频总数"></el-table-column>

     

      <el-table-column label="操作" width="280">
       <template slot-scope="scope">

      
        <el-button type="primary" v-if="showDetail" size="mini" @click="handleDetail(scope.row)"> 查看</el-button>
        <!-- <el-button size="mini" v-if="showClean" type="warning" @click="cleanAds(scope.row)">清除</el-button> -->
       </template>
      </el-table-column>
     </el-table>
     <el-pagination v-if="listType==0" background layout="prev, pager, next, sizes, total, jumper" :page-sizes="[10, 20, 50, 100]" :page-size="seekData.pageSize" :current-page="seekData.pageNum" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange"></el-pagination>
    </div>
   </el-card>
  </el-row>

  <addView ref="add" :upLoadType="upLoadType" :brandSelectData="brandSelectData" />
  <adsDetail ref="detail" :upLoadType="upLoadType" :brandSelectData="brandSelectData" :showDelete="showDelete"  :showClean="showClean" :showSync="showSync"/>

 </div>
</template>
<script>
import {
 getBrandSelect,
 getAdsList,
 getHotelSelect,
 getPanelNotSet,
 advertiseClean,
 panelSync,
} from "@/api";
import { showLabel } from "@/api/common";
import addView from "./addView";
import adsDetail from "./adsDetail";

export default {
 components: {
  addView,
  adsDetail
 },
 // name:"pp",
 data() {
  return {
    height:0,
   brandSelectData: null,
   hotelSelectData: [],
   selectList: [],
   upLoadType: [
    {
     value: 0,
     label: "图片",
    },
    {
     value: 1,
     label: "视频",
    },
   ],
   loading: false,
   // 品牌列表数据
   tableData: [],
   // 查询的数据
   total: null,
   seekData: {
    brandId: null,
    hotelId: null,
    title: null,
    // pmsHotelId: null,
   },
   pageSize: 10,
   pageNum: 1,

   query: {},
  };
 },
 beforeMount() {
  let h = document.documentElement.clientHeight || document.body.clientHeight;
  this.height = h - 240;
 },

 computed: {
  imgList() {
   let arr = [];
   let list = this.selectList.length ? this.selectList : this.tableData;
   list.forEach((item) => {
    if (item.advType === 0 && item.path) {
     arr.push(item.path);
    }
   });
   return arr;
  },
  // 权限
  showAdd() {
   return this.$store.state.roleMenu.includes("adsManage:add");
  },
  showDelete() {
   return this.$store.state.roleMenu.includes("adsManage:delete");
  },
  showClean() {
   return this.$store.state.roleMenu.includes("adsManage:clear");
  },
  showDetail() {
   return this.$store.state.roleMenu.includes("adsManage:view");
  },
  showSync() {
   return this.$store.state.roleMenu.includes("adsManage:sync");
  },
 },
 created() {
  // 调用初始化表格数据方法
  this.initialize();
  this.getList();
 },
 methods: {
  initialize() {
   getBrandSelect().then((res) => {
    if (res.status === 200) {
     this.brandSelectData = res.data.data;
    }
   });
  },
  tab(list) {
   this.selectList = list;
   console.log(list);
  },
  // 选择框
  selectOne(id) {
   console.log(id);
   this.seekData.hotelId = null;
   if (!id) return;
   getHotelSelect(id).then((res) => {
    this.hotelSelectData = res.data.data;
   });
  },
  openpopup() {
   this.$refs.add.openSet();
  },
  
  showLabel(type) {
   return showLabel(type, this.upLoadType);
  },

  // 初始化表格数据
  getList() {
   this.loading = true;
   this.listType = 0;
   getAdsList(this.pageNum, this.pageSize, this.query)
    .then((res) => {
     this.loading = false;
     if (res.status === 200) {
      this.tableData = res.data.data.rows;
      this.total = res.data.data.total;
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },
  handleDetail(row){
    this.$refs.detail.openpopup(row)
  },
  handleSync() {
   if (this.selectList.length == 0) {
    this.$message.warning("请选择需要同步的图片");
    return;
   }
   this.$confirm("此操作将所选中的图片同步到该酒店, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
   })
    .then(() => {
     this.syncReq();
    })
    .catch(() => {});
  },
  syncReq(id) {
   this.loading = true;
   panelSync(id)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success("同步成功");
      this.getList();
     } else {
      this.$message.error("同步失败");
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },

  cleanAds(row) {
   this.$confirm("此操作将清除该门店下的所有广告, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
   })
    .then(() => {
     this.cleanAdsReq(row.pmsHotelId);
    })
    .catch(() => {});
  },

  cleanAdsReq(id) {
   this.loading = true;
   advertiseClean(id)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success("清除成功");
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },

  // 查询按钮
  handleSearch() {
   this.pageNum = 1;
   this.query = Object.assign({}, this.seekData);
   console.log(this.query);
   this.getList();
  },
  // 重置按钮
  reset() {
   this.pageNum = 1;
   this.$refs.seekData.resetFields();
   this.handleSearch();
  },
  //分页
  handleCurrentChange(pageNum) {
   this.pageNum = pageNum;
   this.getList();
  },
  handleSizeChange(psize) {
   this.pageSize = psize;
   this.pageNum = 1;
   this.getList();
  },
 },
};
</script>
<style lang="scss" scoped>
.avatarImg {
 height: 40px;
}
.my-box {
 .el-row {
  margin-bottom: 10px;
  &:last-child {
   margin-bottom: 0;
  }
 }
 .el-col {
  border-radius: 4px;
 }
 .my-input {
  max-width: 209px;
 }
}
.checkBox {
 display: inline-block;
 width: 28%;
}
</style>
