<template>
 <div>
  <el-dialog title="广告详情" :close-on-click-modal="false" top="3%" :visible.sync="showDialog" width="60%" label-width="80px">
   <div slot="title">
    <el-row>
     <el-col :span="5">
      <el-button type="success" size="mini" @click="handleSync" v-if="showSync"> 同步</el-button>
      <el-button type="warning" size="mini" @click="cleanAds" v-if="showClean"> 清除</el-button>
      <span class="beforeDiv"></span>
     </el-col>
     <el-col :span="4">
      <span class="bold">
       广告详情
      </span>
     </el-col>
     <el-col :span="12">
      {{detailData.brandName}} - {{detailData.hotelName}} <span>pmsHotelId:{{detailData.pmsHotelId}}</span>
     </el-col>
    </el-row>
   </div>
   <el-card shadow="always">
    <div class="detailTab" v-infinite-scroll="load" infinite-scroll-disabled="disabled" :style="'height:'+height+'px'">
     <el-table ref="multipleTable" :data="tablist" tooltip-effect="dark" style="width: 100%" @selection-change="tab">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column prop="advType" label="广告类型">
       <template slot-scope="scope">
        <div>
         {{showLabel(scope.row.advType)}}
        </div>
       </template>
      </el-table-column>
      <el-table-column label="图片/视频">
       <template slot-scope="scope">
        <div>
         <el-image class="avatarImg" :src="scope.row.path" :preview-src-list="imgList">
         </el-image>
        </div>
       </template>
      </el-table-column>
      <el-table-column label="状态">
       <template slot-scope="scope">
        <div>
         {{scope.row.isShowing==1?'正在展示':''}}

        </div>
       </template>
      </el-table-column>
      <el-table-column label="操作" width="80">
       <template slot-scope="scope">
        <el-button size="mini" type="danger" v-if="showDelete" @click="handleDelete(scope.row,scope.$index)">删除</el-button>
       </template>
      </el-table-column>
     </el-table>
    </div>
   </el-card>

   <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="showDialog=false">关闭</el-button>
    <!-- <el-button type="primary" @click="confirmAdd('addform')" :loading="loading">确 定</el-button> -->
   </div>
  </el-dialog>
 </div>
</template>
<script>
import {
 advertiseSync,
 getAdsDetailList,
 advertiseClean,
 advertiseDelete,
} from "@/api";
import { showLabel } from "@/api/common";

export default {
 props: [
  "upLoadType",
  "brandSelectData",
  "showDelete",
  "showClean",
  "showSync",
 ],
 data() {
  return {
   query: {},
   pageSize: 10,
   pageNum: 1,
   total: 0,
   loading: false,
   showDialog: false,
   height: 0,
   detailData: {},
   selectList: [],
   tablist: [],
  };
 },
 computed: {
  noMore() {
   return this.tablist.length >= this.total;
  },
  disabled() {
   return this.loading || this.noMore;
  },
  imgList() {
   let arr = [];
   this.tablist.forEach((item) => {
    if (item.path) {
     arr.push(item.path);
    }
   });
   return arr;
  },
 },
 beforeMount() {
  let h = document.documentElement.clientHeight || document.body.clientHeight;
  this.height = h - 350;
 },
 methods: {
  handleDelete(row, index) {
   this.$confirm("此操作将删除该广告, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
   })
    .then(() => {
     console.log(index, "--");
     // this.tablist.splice(index,1)
     this.deleteAdsReq(row.advId, index);
    })
    .catch(() => {});
  },
  deleteAdsReq(id, index = 0) {
   this.loading = true;
   advertiseDelete(id)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success("删除成功");
      this.tablist.splice(index, 1);
      this.$foreUpdate();
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },
  cleanAds() {
   this.$confirm("此操作将清除该门店下的所有广告, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
   })
    .then(() => {
     this.cleanAdsReq(this.detailData.pmsHotelId);
    })
    .catch(() => {});
  },

  cleanAdsReq(id) {
   this.loading = true;
   advertiseClean(id)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success("清除成功");
      this.cleanIsShowIng();
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },
  cleanIsShowIng() {
   this.tablist.forEach((item) => {
    item.isShowing = 0;
   });
   console.log(this.tablist, "-------------this.tablist");
   setTimeout(() => {
    this.toggleSelection(this.tablist);
   }, 100);
  },
  getList() {
   this.loading = true;
   if (this.pageNum == 1) {
    this.tablist = [];
   }
   getAdsDetailList(this.pageNum, this.pageSize, this.query)
    .then((res) => {
     this.loading = false;
     if (res.status === 200) {
      // this.tablist = res.data.data.rows;
      let list = this.tablist.concat(res.data.data.rows);
      this.tablist = Array.from(new Set(list));
      this.total = res.data.data.total;
      setTimeout(() => {
       this.toggleSelection(this.tablist);
      }, 100);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },
  toggleSelection(list) {
   let rows = list.filter((item) => item.isShowing == 1);
   console.log(rows, "-------------rows");
   if (rows && rows.length) {
    rows.forEach((row) => {
     this.$refs.multipleTable.toggleRowSelection(row);
    });
   } else {
    this.$refs.multipleTable.clearSelection();
   }
  },
  load() {
   console.log("------------------");
   if (this.loading) return;
   this.pageNum++;
   this.getList();
  },
  handleSync() {
   if (this.selectList.length == 0) {
    this.$message.warning("请选择需要同步的图片");
    return;
   }
   this.$confirm("此操作将所选中的图片同步到该酒店, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
   })
    .then(() => {
     let data = {
      pmsHotelId: this.detailData.pmsHotelId,
      // advType: this.detailData.advType,
      advIds: [],
     };
     this.selectList.forEach((item) => {
      data.advIds.push(item.advId);
     });
     this.syncReq(data);
    })
    .catch(() => {});
  },
  syncReq(data) {
   this.loading = true;
   advertiseSync(data)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success(res.data.message);
      this.setIsShowIng(data.advIds);
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },
  setIsShowIng(list) {
   this.tablist.forEach((item) => {
    if (list.includes(item.advId)) {
     item.isShowing = 1;
    } else {
     item.isShowing = 0;
    }
   });
   this.$foreUpdate() 
  },
  tab(list) {
   this.selectList = list;
  },
  showLabel(type) {
   return showLabel(type, this.upLoadType);
  },
  openpopup(row) {
   this.showDialog = true;
   if (this.detailData.pmsHotelId != row.pmsHotelId) {
    this.loading = true;
    this.pageNum = 1;
    this.query = {
     pmsHotelId: row.pmsHotelId,
    };
    this.detailData = JSON.parse(JSON.stringify(row));
    this.getList();
   }
  },
 },
};
</script>
<style lang="scss" scoped>
.bold {
 font-weight: bold;
 font-size: 20px;
}
.detailTab {
 overflow-y: auto;
}
.avatarImg {
 height: 50px;
}
</style>


